// Core
import { graphql } from "gatsby";
import get from "lodash/get";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  ArticleCard,
  Breadbrumbs,
  Pagination,
} from "../../components/";
import Layout from "../../layout/";

class BlogAuthorTemplate extends React.Component {
  render() {
    const articles = get(this, "props.data.allContentfulJournalArticle.edges");
    const author = get(this, "props.data.contentfulJournalAuthor");
    const { current_page, number_of_pages, pathname } = this.props.pageContext;

    return (
      <Layout
        seo={{
          pathname: pathname,
          title: `Articles by ${author.name}`,
        }}
      >
        <section className="container pt100">
          <Breadbrumbs
            breadcrumbs={[
              {
                label: "Home",
                url: "/",
              },
              {
                label: "Journal",
                url: "/journal",
              },
              {
                label: author.name,
              },
            ]}
          />

          <h1 className="h2 mb20 mt0">
            Articles by {author.name}
          </h1>
        </section>

        <section className="container pb100 pt50 rpt20">
          <div className={styles["articles-grid"]}>
            {articles.map(({ node }) => {
              return (
                <ArticleCard
                  article={node}
                  key={node.slug}
                />
              );
            })}
          </div>

          {number_of_pages > 1 && (
            <div className="mt80">
              <Pagination
                current={current_page}
                pathname={pathname}
                total={number_of_pages}
              />
            </div>
          )}
        </section>
      </Layout>
    );
  }
}

export default BlogAuthorTemplate;

export const pageQuery = graphql`
  query BlogAuthorTemplateQuery($author_slug: String!, $skip: Int!, $limit: Int!) {
    allContentfulJournalArticle(
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
      filter: { author: { slug: { eq: $author_slug } } }
    ) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(aspectRatio: 1.8, layout: FULL_WIDTH, placeholder: BLURRED)
          }
          topic {
            name
            slug
          }
          description {
            description
          }
          author {
            name
            slug
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    contentfulJournalAuthor(slug: { eq: $author_slug }) {
      name
    }
  }
`;
